import React, { FunctionComponent, useState, useRef, createRef } from "react";
import Modalcomponent from "./ModalComponent";
import { Button, InputGroup, Input, InputGroupAddon, Alert} from 'reactstrap';


interface Props {
    address: string
}

const InputReadonlyWithCopy: FunctionComponent<Props> = ({
    address
}: Props) => {
    const [show, setShow] = useState(false);
    const inputRef = useRef<any>();

        const copyToClipboard = function(e: any){
        inputRef.current.select();
        document.execCommand('copy');
        // e.target.focus();
        setShow(true);
        window.setTimeout(()=>{
            setShow(false);
        }, 3000);
    }

    return (
        <div>
            <InputGroup style={{borderRadius:"3px", marginLeft:"8%"}}>
                
                    <input readOnly ref={inputRef} style={{fontSize:"15px", width:"78%"}} value={address} />
                      <InputGroupAddon addonType="append">
                        <Button onClick={copyToClipboard} style={{fontFamily:"Gidole-Regular", background:"rgba(0,159,255,0.5)", color:"black"}}>Copy</Button>
                      </InputGroupAddon>
                    </InputGroup>
                    <Alert isOpen={show}>
                        Copied!
                    </Alert>
        </div>
    );
};

export default InputReadonlyWithCopy;
