import * as React from 'react';
import { connect } from 'react-redux';
import { Card, CardImg, CardText, CardBody, CardTitle, CardHeader, CardSubtitle, Button, Container, Row, Col, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from "../store";
import * as HomeStore from "../store/HomeStore";
import { number } from 'prop-types';
import ModalWithTextTrigger from './ModalWithTextTrigger';
import InputReadonlyWithCopy from './InputReadonlyWithCopy';
import '../styles/Home.css';
// import ltonetworktransfer from './ltonetworktransfer.png';

type HomeProps = HomeStore.ApiInfoState & typeof HomeStore.actionCreators & RouteComponentProps<{}>;
type HomeState = { windowWidth: number }
class Home extends React.PureComponent<HomeProps, HomeState> {

  constructor(props: HomeProps){
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    }
  }

  public componentDidMount(){
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.ensureDataFetched();
  }

  private ensureDataFetched(){
    this.props.getApiInfo();
  }

  private getTotalLeases(){
    var apiInfo = this.props.apiInfo;
    var numberOfLeases = 0;
    if(apiInfo.addresses !== undefined){
      apiInfo.addresses.forEach(element => {
          numberOfLeases++;
      });
    }
    return numberOfLeases;
  }

  private getTotalStaked(){
    var apiInfo = this.props.apiInfo;
    return (apiInfo.totalStaked / 100000000).toFixed(2);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };



  public render(){
    const { windowWidth } = this.state;
    const isMobile = windowWidth <= 500;
    if(!isMobile){
      return (
        <div>
          <Container>
            <Row xs="2">
              <Col>
                <Card className="info-card container-module left-module">
                  <CardHeader className="header">What is $LTO Leasing?</CardHeader>
                  {/* <CardImg bottom style={{width:"20%", opacity:"50%", position: "absolute", right: "10%", top:"35%"}} src={mining} alt="Make money"></CardImg> */}
                  <CardBody>
                    <CardText>If you are holding $LTO, there's an opportunity for you to earn more, with 0 risk. <br />
                      Leasing on the LTO Network is a simpler way to take part in the Proof of Stake concept that the network uses.<br />
                      For a blockchain to forge blocks, nodes are needed for validating transactions. The nodes forge blocks on the chain and get rewarded $LTO for their work.<br /> You can lease your $LTO coins to our running node and get a good share of the $LTO rewards the node makes. <br/>
                      The rewards will be transferred to your wallet whenever a payout is made.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card className="info-card container-module right-module">
                  <CardHeader className="header">The Knights Bay Node</CardHeader>
                  {/* <CardImg bottom style={{width:"20%", opacity:"50%", position: "absolute", right: "10%", top:"35%"}} src={mining} alt="Make money"></CardImg> */}
                  <CardBody>
                    <CardTitle tag="h5">Statistics from our node</CardTitle>
                    <Row xs="2">
                      <Col>
                        <Card className="info-card container-module left-module">
                          <CardHeader className="header">% of Rewards Shared</CardHeader>
                          <CardBody style={{margin:"auto", fontSize:"1.8vw"}}>98%</CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="info-card container-module right-module">
                          <CardHeader className="header">Total $LTO Staked</CardHeader>
                          <CardBody style={{margin:"auto", fontSize:"1.8vw"}}>{this.getTotalStaked()}</CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row xs="2">
                      <Col>
                        <Card className="info-card container-module left-module bottom-module">
                          <CardHeader className="header"># of Leasers</CardHeader>
                          <CardBody style={{margin:"auto", fontSize:"2vw"}}>{this.getTotalLeases()}</CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="info-card container-module right-module bottom-module">
                          <CardHeader className="header">Payout date</CardHeader>
                          <CardBody style={{margin:"auto", paddingTop:"15%", fontFamily:"monospace"}}>Every Friday</CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row xs="2">
              <Col>
                <Card className="info-card container-module bottom-module" style={{borderRadius:"15px"}}>
                <CardHeader className="header">We offer optional, automatic compounding!</CardHeader>
                  <CardBody style={{textAlign:"center"}}>
                    <Row xs="2">
                        <Col>
                                          <CardText>For your convenience, you can compound your weekly rewards, instead of having to re-lease them.<br />It's really easy to set up, and it's all handled through our automatic Telegram bot.<br />Join our telegram group for more info and if you have any questions!<br /></CardText>

                         

                      </Col>
                                  </Row>
                                  <Row xs="2" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <a href="https://t.me/knightsbayleasing/">
                                          <Button className="telegram-button" size="lg" style={{ float: "left", background: "#0088cc", border: "none", color: "black", boxShadow: "2px 2px rgba(0,0,0,0.2)" }}>TG: Knights' Bay Leasing LTO</Button>
                                      </a>
                                  </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "3%", textAlign: "center" }}>
              <Col>
                  <Card className="info-card container-module bottom-module" style={{ borderRadius: "15px" }}>
                    <CardHeader className="header">Lease to our node address</CardHeader>
                      <CardBody style={{ textAlign: "center" }}>
                          <Row>
                              <Col>
                                  <InputReadonlyWithCopy address="3Jt1mBoziZmoGDaYe1UbGygGMsGS493vkgN" />
                              </Col>
                          </Row>
                      </CardBody>
                  </Card>
              </Col>
          </Row>
          </Container>
          <Container style={{margin:"7% auto", paddingLeft:"3%"}}>
          <Row className="regular-style">
              <h2>How to start leasing:</h2>
            </Row>
            <Row className="regular-style" style={{marginTop:"3%"}}>
              <Col xs="1">
                <h2>1</h2>
              </Col>
              <Col style={{margin:"auto"}}>
                Log into or create your LTO Mainnet wallet at <a href="https://wallet.lto.network/">https://wallet.lto.network/</a>)
                <hr></hr>
              </Col>
            </Row>
            <Row className="regular-style" style={{marginTop:"3%"}}>
              <Col xs="1">
                <h2>2</h2>
              </Col>
              <Col style={{margin:"auto"}}>
                Aquire $LTO (via ex. <a href="https://binance.com/">binance.com</a>)
                <hr></hr>
              </Col>
            </Row>
            <Row className="regular-style" style={{marginTop:"3%"}}>
              <Col xs="1"><h2>3</h2></Col>
              <Col>Transfer the $LTO to your mainnet wallet (if through binance, make sure you withdraw to the LTO Network)</Col>
            </Row>
            <Row className="regular-style" style={{marginTop:"3%"}}>
              <Col xs="1"><h2>4</h2></Col>
              <Col>Log into your mainnet wallet and click on the "Leasing" tab on the left</Col>
            </Row>
            <Row className="regular-style" style={{marginTop:"3%"}}>
              <Col xs="1"><h2>5</h2></Col>
              <Col>Select "Knights' Bay Leasing" in the dropdown list or enter our node address (listed above)</Col>
            </Row>
            <Row className="regular-style" style={{marginTop:"3%"}}>
              <Col xs="1"><h2>6</h2></Col>
              <Col>Select amount and click "Start lease".</Col>
            </Row>
            <Row className="regular-style" style={{marginTop:"3%"}}>
              <Col><h1>Done! Now relax and wait for your $LTO rewards to start coming in!</h1></Col>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <div>
        <Container>
        <Row className="bottom-module" style={{marginBottom:"6%"}}>
            <Col>
            <Card className="info-card-mobile container-module bottom-module" style={{borderRadius:"15px"}}>
                  <CardHeader className="header">Lease to our node address</CardHeader>
                  <CardBody className="regular-style" style={{textAlign:"center"}}>
                    <Row>
                      <Col>
                    <InputReadonlyWithCopy address="3Jt1mBoziZmoGDaYe1UbGygGMsGS493vkgN" />
                    </Col>
                    </Row>
                    </CardBody>
                </Card>
            </Col>
                </Row>
                <Row className="bottom-module" style={{ marginBottom: "6%" }}>
                <Col>
                    <Card className="info-card-mobile container-module">
                        <CardHeader className="header">Optional compounding</CardHeader>
                        <CardBody>
                                <CardText>
                                    <Row xs="2" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>All handled through our TG bot!<br />Join our telegram for more info:</Row>
                                    <Row xs="2" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <a href="https://t.me/knightsbayleasing/">
                                            <Button className="telegram-button" size="sm" style={{ background: "#0088cc", border: "none", color: "black", boxShadow: "2px 2px rgba(0,0,0,0.2)" }}>Join our telegram group!</Button>
                                        </a>
                                    </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
          <Row>
            <Col>
            <Card className="info-card-mobile container-module">
                <CardHeader className="header">What is $LTO Leasing?</CardHeader>
                <CardBody>
                  <CardText>
                    <ul>
                      <li>
                        Nodes forge blocks on the LTO Network blockchain for $LTO rewards
                      </li>
                      <li>
                        Instead of running a node, you can lease to an existing node and get a share of the rewards!
                      </li>
                      <li>
                        Leasing won't move your $LTO, 0 risk
                      </li>
                      <li>
                        You can cancel a lease anytime
                      </li>
                      <li>
                        Payouts transferred directly to your wallet
                      </li>
                      <br></br>
                      <ModalWithTextTrigger text="Click here for steps to start leasing!" modalTitle="How to lease $LTO" modalButtonText="Close" content=""/>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
            <Card className="info-card-mobile container-module bottom-module">
                  <CardHeader>The Knights Bay Node</CardHeader>
                  <CardBody>
                    <CardTitle tag="h5">Information about our node</CardTitle>
                    <Row>
                      <Col>
                        <Card className="info-card-mobile container-module">
                          <CardHeader className="header" style={{textAlign:"center", width:"100%"}}>% of Rewards Shared</CardHeader>
                          <CardBody className="regular-style" style={{margin:"auto", fontSize:"6.5vw", fontFamily:"monospace"}}>98%</CardBody>
                        </Card>
                      </Col>
                      </Row>
                      <Row>
                      <Col>
                        <Card className="info-card-mobile container-module bottom-module">
                          <CardHeader className="header" style={{textAlign:"center", width:"100%"}}>Total $LTO Staked</CardHeader>
                          <CardBody className="regular-style" style={{margin:"auto", fontSize:"8vw"}}>{this.getTotalStaked()}</CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="bottom-module">
                      <Col>
                        <Card className="info-card-mobile container-module bottom-module">
                          <CardHeader className="regular-style header" style={{textAlign:"center", width:"100%"}}># of Leasers</CardHeader>
                          <CardBody style={{margin:"auto", fontSize:"8vw"}}>{this.getTotalLeases()}</CardBody>
                        </Card>
                      </Col>
                      </Row>
                      <Row className="bottom-module">
                      <Col>
                        <Card className="info-card-mobile container-module bottom-module">
                          <CardHeader className="header" style={{textAlign:"center", width:"100%"}}>Payout date</CardHeader>
                          <CardBody className="regular-style" style={{margin:"auto"}}>Every Friday</CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
            </Col>
          </Row>
          <Row className="bottom-module" style={{marginBottom:"6%"}}>
            <Col>
            <Card className="info-card-mobile container-module bottom-module" style={{borderRadius:"15px"}}>
                  <CardHeader className="header">Lease to our node address</CardHeader>
                  <CardBody className="regular-style" style={{textAlign:"center"}}>
                    <Row>
                      <Col>
                    <InputReadonlyWithCopy address="3Jt1mBoziZmoGDaYe1UbGygGMsGS493vkgN" />
                    </Col>
                    </Row>
                    <Row>
                      <Col>
                    <a href="https://t.me/knightsbayleasing/">
                      <Button className="telegram-button" size="sm" style={{background:"#0088cc", border:"none", color:"black", boxShadow:"2px 2px rgba(0,0,0,0.2)"}}>Join our telegram group!</Button>
                    </a>
                    </Col>
                    </Row>
                    </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}



export default connect(
  (state: ApplicationState) => state.apiInfo,
  HomeStore.actionCreators
)(Home as any);
