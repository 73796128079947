import React, { PureComponent } from "react";
import { ModalBody, ModalHeader, Modal, Button, ModalFooter } from "reactstrap";

interface Props {
    toggle: any; // Maybe type this to a function?
    modalState: any; // Maybe type this to some interface later.
    modalContent: any; // Want to keep as any to allow more than just text.
    modalTitle: any;
    modalButtonText: any;
}

export default class ModalComponent extends PureComponent<Props> {
    render() {
        const { toggle, modalState, modalContent, modalTitle } = this.props;
        return (
            <div>
                <Modal isOpen={modalState} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
                    <ModalBody>
                        <ol>
                            <li>Create a wallet on the LTO Mainnet - <a href="https://wallet.lto.network/">https://wallet.lto.network/</a></li>
                            <li>Aquire $LTO (on ex. <a href="https://binance.com/">Binance.com</a>)</li>
                            <li>Send the $LTO to your LTO Mainnet wallet or swap $LTO from ERC20 exchanges/wallets using the bridge (instructions found under "Bridge" tab when logged in on the mainnet wallet).</li>
                            <li>In your mainnet wallet, click the leasing tab</li>
                            <li>Choose "Knights' Bay Leasing" in the dropdown list</li>
                            <li>Select amount to lease and click done</li>
                        </ol>
                        You're all set! Now just wait for your rewards!
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggle}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
