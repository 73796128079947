import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";

export interface ApiInfo{
    addresses: Address[];
    totalStaked: number;
}

export interface Address{
    type: number;
    id: string;
    sender: string;
    senderPublicKey: string;
    fee: number;
    timestamp: number;
    proofs: string[];
    version: number;
    amount: number;
    recipient: string;
    height: number;
}

export interface ApiInfoState {
    apiInfo: ApiInfo | { addresses: Address[], totalStaked: 0 };
    isLoading: boolean;
}

export interface GetApiInfoAction {
    type: "GET_API_INFO";
    apiInfo: ApiInfo;
}

export interface RetrievingApiInfoAction{
    type: "RETRIEVING_API_INFO"
}

export type KnownAction =
    | GetApiInfoAction
    | RetrievingApiInfoAction;

export const actionCreators = {
    getApiInfo: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`/api/info`)
        .then(response => response.json() as Promise<ApiInfo>)
        .then(data => {
            dispatch({
                type: "GET_API_INFO",
                apiInfo: data
            });
        });
        dispatch({
            type: "RETRIEVING_API_INFO"
        });
    }
}

const unloadedState: ApiInfoState = {
    apiInfo: { addresses: [], totalStaked: 0 },
    isLoading: false
};

export const reducer: Reducer<ApiInfoState> = (
    state: ApiInfoState | undefined,
    incomingAction: Action
): ApiInfoState => {
    if(state === undefined){
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch(action.type){
        case "GET_API_INFO":
            return {
                apiInfo: action.apiInfo,
                isLoading: false
            };
        case "RETRIEVING_API_INFO":
            return {
                apiInfo: state.apiInfo,
                isLoading: true
            };
        default:
            return state;
    }
};
