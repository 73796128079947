import React, { FunctionComponent, useState } from "react";
import { Button } from "reactstrap";
import Modalcomponent from "./ModalComponent";

interface Props {
    buttonStyleClass?: string;
    text: string;
    content: any;
    modalButtonText: any;
    modalTitle: any;
}

const ModalWithButtonTrigger: FunctionComponent<Props> = ({
    buttonStyleClass,
    text,
    content,
    modalButtonText,
    modalTitle
}: Props) => {
    const [modalState, setModal] = useState(false);
    const toggle = () => setModal(!modalState);
    return (
        <div onClick={toggle}>
            <i style={{textDecoration:"underline"}}>{text}</i>  
            <Modalcomponent
                toggle={toggle}
                modalState={modalState}
                modalContent={content}
                modalTitle={modalTitle}
                modalButtonText={modalButtonText}
            />
        </div>
    );
};

export default ModalWithButtonTrigger;
