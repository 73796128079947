import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from './logo.png';

export default class NavMenu extends React.PureComponent<{}, { windowWidth: number }> {
    public state = {
        windowWidth: window.innerWidth
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
    };

    public render() {
        const { windowWidth } = this.state;
        const isMobile = windowWidth <= 500;
        if(isMobile){
            return (
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                        <Container>
                            <Row>
                                <Col xs="3">
                                    <img src={logo} style={{width:"100%"}}/>
                                </Col>
                                <Col xs="8">
                                    <NavbarBrand style={{padding:"10% 0"}} tag={Link} to="/">Knights' Bay $LTO Leasing</NavbarBrand>
                                </Col>
                            </Row>
                        </Container>
                    </Navbar>
                </header>
            );
        }

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <Row style={{margin:"auto"}}>
                        <img src={logo} style={{width:"10%", margin:"0"}}/>
                        <NavbarBrand tag={Link} style={{textAlign:"center", margin:"auto 2%"}} to="/">Knights' Bay $LTO Leasing</NavbarBrand>
                        </Row>
                    </Container>
                </Navbar>
            </header>
        );
        
    }
}
